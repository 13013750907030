import { StaticContent, StaticContentState } from './types'
import { Module } from 'vuex'
import { ObjectUtils } from 'widgets-utils'

export const initialStaticContentState: StaticContentState = {
  merchantId: 0,
  currency: '',
  guid: '',
  isMultiCurrencyEnabled: false,
  baseUrl: undefined,
  forceEmailCapture: false
}

class StoreModuleCreator {
  create (staticContent: StaticContent, guid: string): Module<StaticContentState, any> {
    let cleanedStaticContent = ObjectUtils.removeEmptyFields(staticContent)
    cleanedStaticContent.guid = guid
    const initial: StaticContentState = {
      ...initialStaticContentState,
      ...cleanedStaticContent
    }
    return {
      state: initial
    }
  }
}

export default new StoreModuleCreator()
