import { ContextCommit, ErrorType, FETCH_COUPON_CODE, SET_COUPON_CODE, SET_COUPON_IS_LOADING, SET_ERROR_TYPE, SET_DUMMY_COUPON, PROCESS_COUPON, APPLY_COUPON, SET_SHOULD_AUTO_APPLY, APPLY_COUPON_FROM_STORAGE } from './types'
import VueLoyaltyApi from '@/services/loyalty-api/vue-loyalty-api'
import { dummyCoupon } from '@/models/dummyCoupon'
import { LocalStorage } from 'widgets-shared-services'
import { UrlUtils } from 'widgets-utils'

export const actions = {
  async [FETCH_COUPON_CODE] ({ rootState, commit, dispatch }: ContextCommit) {
    if (rootState.mode.isPreview || rootState.mode.isReadOnly) {
      dispatch(SET_DUMMY_COUPON)
      return
    }
    commit(SET_COUPON_IS_LOADING, true)
    const queryString = window.location.search.substring(1)
    const urlParams = UrlUtils.getQueryParams(queryString)
    const crefId = urlParams.cref_id
    const currency = rootState.currency.isMultiCurrencyEnabled && rootState.platform.name === 'custom' ? rootState.currency.cartCurrency : null
    const response = await VueLoyaltyApi.createCouponCode({ merchantId: rootState.staticContent.merchantId, email: rootState.customer.email, currency, crefId })
    commit(SET_COUPON_IS_LOADING, false)

    dispatch(PROCESS_COUPON, response)
  },
  async [PROCESS_COUPON] ({ commit, rootState, dispatch }: ContextCommit, response: any) {
    if (!response) {
      commit(SET_ERROR_TYPE, ErrorType.General)
    } else if (response.isApplePrivateRelayIp) {
      commit(SET_ERROR_TYPE, ErrorType.IsApplePrivateRelayIp)
    } else if (!response.couponCode) {
      commit(SET_ERROR_TYPE, ErrorType.NotEligible)
    } else if (response.couponCode && response.uses > 0) {
      commit(SET_ERROR_TYPE, ErrorType.UsedCoupon)
    } else {
      commit(SET_COUPON_CODE, response.couponCode)
      if (rootState.mode.isPreview || rootState.mode.isReadOnly) {
        commit(SET_ERROR_TYPE, null)
        return
      }
      await dispatch(SET_SHOULD_AUTO_APPLY)
      dispatch(APPLY_COUPON, response.couponCode)
    }
  },
  async [SET_SHOULD_AUTO_APPLY] ({ commit, rootState }: ContextCommit) {
    const campaigns = ['ReferralCampaign']
    const response = await VueLoyaltyApi.getCampaigns(rootState.analytics.guid, rootState.staticContent.merchantId, campaigns)
    const campaign = response[0]
    const localStorageCoupon = LocalStorage.get('loyalty-coupon-code')
    const localStorageAppliedCoupon = LocalStorage.get('loyalty-applied-coupon-code')
    const couponApplied = localStorageAppliedCoupon != null && localStorageAppliedCoupon === localStorageCoupon
    const shouldAutoApplyCoupon = campaign && campaign.type === 'ReferralCampaign' && campaign.shouldAutoApplyCoupon && !couponApplied
    commit(SET_SHOULD_AUTO_APPLY, shouldAutoApplyCoupon)
  },
  async [APPLY_COUPON] ({ state, getters }: ContextCommit, couponCode: string) {
    if (state.shouldAutoApply) {
      LocalStorage.set('loyalty-coupon-code', couponCode)
      const couponService = getters.couponService
      couponService.applyCoupon(couponCode)
      LocalStorage.set('loyalty-applied-coupon-code', couponCode)
    }
  },
  [SET_DUMMY_COUPON] ({ rootState, dispatch }: ContextCommit) {
    if (rootState.mode.notEligible) {
      dispatch(PROCESS_COUPON, dummyCoupon.notEligible)
    } else if (rootState.mode.alreadyUsed) {
      dispatch(PROCESS_COUPON, dummyCoupon.alreadyUsed)
    } else if (rootState.mode.generalFailure) {
      dispatch(PROCESS_COUPON, false)
    } else if (rootState.mode.applePrivateRelayIp) {
      dispatch(PROCESS_COUPON, dummyCoupon.applePrivateRelayIp)
    } else {
      dispatch(PROCESS_COUPON, dummyCoupon.successful)
    }
  },
  async [APPLY_COUPON_FROM_STORAGE] ({ rootState, state, dispatch }: ContextCommit) {
    if (rootState.mode.isPreview || rootState.mode.isReadOnly) {
      return
    }
    await dispatch(SET_SHOULD_AUTO_APPLY)
    if (state.shouldAutoApply) {
      const localStorageCoupon = LocalStorage.get('loyalty-coupon-code')
      if (localStorageCoupon) {
        await dispatch(APPLY_COUPON, localStorageCoupon)
      }
    }
  }
}
