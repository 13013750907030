import { LoyaltyApi } from 'loyalty-shared-services'

class VueLoyaltyApi {
  private api: LoyaltyApi
  private baseUrl: string

  constructor () {
    this.baseUrl = process.env.VUE_APP_SWELL_BASE_URL || 'https://loyalty.yotpo.com'
    this.api = this.baseUrl ? new LoyaltyApi(this.baseUrl) : new LoyaltyApi()
  }

  async getCampaigns (guid: string, merchantId: number, campaigns: string[]): Promise<Campaign[]> {
    const config = {
      params: {
        guid,
        merchant_id: merchantId,
        campaign_types: campaigns
      }
    }
    const response: any = await this.api.get(`${this.baseUrl}/api/public/v1/campaigns`, config)

    return response.data.map((campaign: any): Campaign => {
      return {
        id: campaign.id,
        type: campaign.type,
        shouldAutoApplyCoupon: campaign.referral_auto_apply_coupon
      }
    })
  }

  async createCouponCode (params: CouponCodeParams): Promise<CouponCodeResponse|null> {
    let requestBody: any = {
      merchant_id: params.merchantId,
      type: 'ReferralLinkClick'
    }
    if (params.email) {
      requestBody.customer_email = params.email
    }
    if (params.currency) {
      requestBody.currency = params.currency
    }
    if (params.crefId) {
      requestBody.cref_id = params.crefId
    }
    let response
    try {
      response = await this.api.post(`${this.baseUrl}/api/v1/user_actions`, requestBody)
    } catch (exception) {
      return null
    }
    const hasUses = response.data.point_redemption && response.data.point_redemption.redemption_code &&
                      response.data.point_redemption.redemption_code.uses
    return {
      pointRedemptionExists: response.data.point_redemption,
      couponCode: response.data.point_redemption ? response.data.point_redemption.reward_text : null,
      uses: hasUses ? response.data.point_redemption.redemption_code.uses : null,
      isApplePrivateRelayIp: response.data.is_apple_private_relay_ip
    }
  }
}

export interface Campaign {
  id: number
  type: string
  shouldAutoApplyCoupon: boolean
}

export interface CouponCodeParams {
  merchantId: number
  email?: string
  currency?: string | null
  crefId?: string | null
}

export interface CouponCodeResponse {
  pointRedemptionExists: boolean
  couponCode: string
  uses: number
  isApplePrivateRelayIp: boolean
}

export default new VueLoyaltyApi()
